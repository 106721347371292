import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { MetaData, UserContent } from '../components';
import Button from '../components/Button';

type Props = {
	data: any;
};

const Container = styled.div`
	min-height: 85vh;
	font-family: ${(props) => props.theme.font.primary};
`;

const Image = styled.img`
	max-width: 400px;
	/* border-radius: 100%;
	max-height: 200px;
	aspect-ratio: 1; */
	object-fit: contain;
	/* object-fit: cover; */
`;

const CenterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 4rem;
	margin-top: 4rem;
`;
const PLACEHOLDER = '/images/logo-square.jpg';

const PartnershipPage: React.FC<Props> = (props) => {
	const partner = props?.data?.wordpressAcfPartner?.acf;

	// console.log(props.data);

	return (
		<React.Fragment>
			<MetaData
				title={partner?.title ?? ''}
				description={partner?.short_description ?? ''}
			/>
			<Container className="w-container w-container--padded">
				<CenterContainer>
					<Image src={partner?.image?.source_url || PLACEHOLDER} />
				</CenterContainer>

				<h2
					className="articleheader center"
					dangerouslySetInnerHTML={{ __html: partner?.title ?? '' }}
				/>

				<div className="w-row">
					<div className="w-col w-col-12">
						<UserContent content={partner?.formatted_description ?? ''} />
					</div>
				</div>
			</Container>
		</React.Fragment>
	);
};

export const query = graphql`
	query GetPartnerPost($id: String!) {
		wordpressAcfPartner(id: { eq: $id }) {
			id
			acf {
				description
				image {
					source_url
				}
				short_description
				title
				formatted_description
			}
		}
	}
`;

export default PartnershipPage;
